import React from "react";
// import Header from "./Components/header";
import Forlocation from "./Components/getLocation";
// import Whatsapp from "./Components/whatsapp/whatsapp";
import Otp from "./Components/otp/otp";
import Otpverification from "./Components/otp/verification";
import Index from "./Components";
import { AuthProvider } from "./Auth/Auth";
import { FanEngageProvider } from "./Auth/fanengage";
import './App.css'
// import { WhatsApp } from "@mui/icons-material";
import { BrowserRouter,Route,Routes } from "react-router-dom";



function App() {
  return (
    <div className="App" >
        <AuthProvider>
          <FanEngageProvider>
            {/* <Header/> */}
            <Forlocation/>
            <Routes>
            {/* <Route path="/" element={<Whatsapp/>} /> */}
            <Route path="/" element={<Otp/>} />
            <Route path="/verification" element={<Otpverification/>} />
            <Route path="/cheer" element={<Index/>} />
            {/* <Route path="/cio" element={<Index/>} /> */}
            {/* <Index/> */}
            </Routes>
            </FanEngageProvider>
        </AuthProvider>
    </div>
  );
}

export default App;
