import React, { useEffect, useState , useContext , useRef} from "react";
import firebaseConfig from "../config";
import 'firebase/compat/auth';
// import 'firebase/compat/analytics';
// import firebase from "firebase/compat";
import { getAuth, RecaptchaVerifier, signInWithCustomToken, signInWithPhoneNumber } from "firebase/auth";
import useLocalStorage from "../Hook/useLocalStorage";
import { validateSignIn , otpSignIn} from "../Service/fanengageapi";
import { FanEngageContext } from "./fanengage";
import { createFanEngage } from "../Service/fanengageapi";
import { useNavigate } from "react-router-dom";
import { url } from "../constants";
import { USER_NAME , WHATSAPP_TOKEN } from "../Api";
import axios from "axios";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

  // const geolocationAPI = navigator.geolocation;

  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [error , setError] = useState('');
  const [token, setToken] = useState(null);
  const [usersid, setUsersid] = useLocalStorage("sid", 0);

  const [loclat, setLoclat] = useLocalStorage("lat", 0);
  const [loclong, setLoclong] = useLocalStorage("long", 0);
  const [userprofileotpMobileNo , setuserprofileOtpMobileNo] = useLocalStorage("userprofileotpMobileNo", '');

  const [apiname , setApiname] = useLocalStorage("apiname" , '');
  const [usernumber, setUserNumber] = useLocalStorage("sid", 0);
  const [userwaName , setUserwaName] = useLocalStorage("username" ,'');
  const [userTeam , setUserTeam] = useLocalStorage("team",0);
  const [otpname , setOtpname] = useLocalStorage("otpname",'');
  const [otpMobileNo , setOtpMobileNo] = useState('')

  const [reconnect , setReconnect] = useState(true);


  let refloclat = useRef(0);
  let refloclong = useRef(0);

  const navigate = useNavigate();

  const setUserDetails = (sid) => {
    // console.log(sid)
    setUsersid(sid);
  }

  const forUsernumber = (waNumber) => {
    setUserNumber(waNumber);
}

  const Apiusername = (waName) => {
    setApiname(waName)
}


  const onLocation = (lat ,long) => {
    // console.log(lat, long);
    if(lat !== null && long !== null && lat !== undefined  && long !== undefined ){
    setLoclat(lat);
    setLoclong(long);
    refloclat.current = lat;
    refloclong.current = long;
    }
    else{
      setLoclat(refloclat.current);
      setLoclong(refloclong.current);
    }
  }

  const whatsappsignin = async (waName,waNumber) => {
    try{
      let data = await axios.get(WHATSAPP_TOKEN + `?name=${waName}&phone=${waNumber}`,
      {
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      },);
      // console.log(data)
      let token = data.data;
      if(token !== ''){
        const auth = getAuth();
        signInWithCustomToken(auth, token)
        .then( async(result) => {
          // console.log(result);
          if(result !== null){
            let output = await result.user.getIdTokenResult();
            // console.log(output)
            validateSignIn(output,waName,loclat,loclong).then((res) =>{
              // console.log(res.data.response.userSignUpId)
              setUserDetails(res.data.response.userSignUpId);
              if(res.data.statuscode == 200){
                // navigate("/cheer")
                navigate("/olympicscheer")
              }
            });
          }
        })
      }
    }
    catch(e){
      console.log(e);
    }
  }

  // const whatsappsignin = (waName,waNumber) => {
  //   // console.log(waName,waNumber)
  //   validateSignIn(waNumber,waName,loclat,loclong).then((res) =>{
  //     console.log(res.data.response.userSignUpId)
  //     setUserDetails(res.data.response.userSignUpId);
  //     if(res.data.statuscode == 200){
  //       navigate("/cheer")
  //     }
  //   });
  // }

  const otpmobileSignIn = (user) => {
    otpSignIn(user,otpname,loclat,loclong).then((data) => {
      setUserDetails(data.data.response.userSignUpId);
      if(data.data.statuscode == 200){
        navigate("/cheer")
      }
    })
  }

  // const olympicsotpmobileSignIn = (user) => {
  //   otpSignIn(user,otpname,loclat,loclong).then((data) => {
  //     setUserDetails(data.data.response.userSignUpId);
  //     if(data.data.statuscode == 200){
  //       // navigate("/cheer")
  //       navigate("/olympicscheer")
  //     }
  //   })
  // }

  const userSelectTeam = (teamid) => {
    // console.log(teamid);
    setUserTeam(teamid)
}

const Userdisplayname = (name) => {
  setUserwaName(name);
}

// const otpUsername = (userName,mobileNo) => {
//   setOtpname(userName);
//   setOtpMobileNo(mobileNo);
//   setuserprofileOtpMobileNo(mobileNo)
// }

const otpUsername = (userName) => {
  setOtpname(userName);
}


useEffect(() => {
  getAuth().onAuthStateChanged((user) => {
    setCurrentUser(user);
    setLoading(false);
  });
},[]);

  // useEffect(() => {
  //   firebaseConfig.auth().onAuthStateChanged((user) => {
  //     setCurrentUser(user);
  //     setLoading(false);

  //   });

  //   firebaseConfig.auth().signInAnonymously()
  //     .then(() => {
  //       // Signed in..
  //       let user = firebaseConfig.auth().currentUser;
  //       if (user) {
  //         user.getIdToken().then((value) => {
  //           // Firebase token  
  //           setToken(value)
  //           validateSignIn(value,loclat,loclong).then((sid) => {
  //             // console.log(loclat,loclong);
  //             setUserDetails(sid);
  //           });
  //         })
  //       }
  //     }).catch((error) => {
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       console.log(errorCode, errorMessage);
  //     });
   
  // }, []);


  // useEffect(() => {
  //   let timeout = null;
  //   if(timeout){
  //     clearInterval(timeout);
  //   }
  //   timeout = setInterval(async () => {
  //     let user = firebaseConfig.auth().currentUser;
  //     if (user) {
  //     const newtoken = await user.getIdToken(true);
  //     setToken(newtoken);      
  //     }
  //   }, 50000);
  
  // return () => {
  //   clearInterval(timeout);
  // }
  // },[])

  return (
    <AuthContext.Provider value={{ loading, currentUser, setUserDetails, usersid,apiname,usernumber, token ,  onLocation , loclat , loclong,forUsernumber , Apiusername ,whatsappsignin , userSelectTeam ,Userdisplayname ,userwaName ,userTeam, otpUsername,otpname,otpmobileSignIn,otpMobileNo,userprofileotpMobileNo}}>
      {children}
    </AuthContext.Provider>
  );
};